import React from "react"
import { Dropdown } from "react-bootstrap"

import "../../styles/component/dropdown.scss"

import DownArrow from "../../assets/svgs/arrow-down.svg"

export default function DropDown(props) {
  const { currentValue, menuItems } = props

  return (
    <Dropdown>
      <Dropdown.Toggle>
        {currentValue}
        <DownArrow className="position-absolute down-arrow" />
      </Dropdown.Toggle>
      <Dropdown.Menu renderOnMount={true}>
        {menuItems.map((item, i) => {
          //pass classname as title/subtitle, if nested items needed
          const { value, action, className } = item
          return (
            <div onClick={action} className={className} key={i}>
              {value}
            </div>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}
