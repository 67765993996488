import { navigate } from "gatsby"
import React from "react"
import { MENU } from "../.."
import "./article-card.scss"

const ArticleCard = ({ news }) => {
  const navigateToURL = (linkedInUrl, page) => {
    if (page === MENU.NEWS) window.open(linkedInUrl, "_blank")
    else if (page === MENU.MEDIA_RELEASES)
      navigate(`/media-release/${linkedInUrl}`)
  }
  return (
    <button
      className="feature-card-wrapper"
      onClick={() => navigateToURL(news?.linkedInUrl, news?.page)}
    >
      <div className="feature-card-background"></div>
      <div className="feature-card-content">
        <div className="image-container">
          <img class="logo" src={news?.thumbnail?.url} />
        </div>
        <div className="font-zodiak heading">{news?.title}</div>
        <span>{news?.name}</span>
      </div>
    </button>
  )
}

export default ArticleCard
